<template>
  <div>
    <v-dialog v-model="dialog" persistent max-width="600px">
      <template v-slot:activator="{ on, attrs }">
        <v-btn small color="success" dark v-bind="attrs" v-on="on">
          {{ $t('MENU.ENTERPRICE.EXPENDITURE.SUBCATEGORY') }} +
        </v-btn>
      </template>
      <v-card>
        <v-card-title>
          <span class="headline">SubKategoriya xarajat qo'shish</span>
        </v-card-title>
        <v-card-text>
          <div class="d-flex">
            <div class="col-6 pl-0">
              <v-text-field
                label="Nomi"
                v-model="nameInput"
                outlined
                dense
              ></v-text-field>
            </div>

            <div class="col-6 pr-0">
              <v-text-field
                label="Kod"
                v-model="codeInput"
                maxLength="4"
                outlined
                dense
              ></v-text-field>
            </div>
          </div>
          <v-combobox
            v-model="categoryInput"
            :items="getAllCostCategories"
            label="Xarajat Kategoriyalari"
            item-text="name"
            outlined
            dense
          ></v-combobox>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="red text-white"
            :disabled="newsubcategorycostLoading"
            @click="dialog = false"
            >Bekor qilish</v-btn
          >
          <v-btn
            color="green text-white"
            :disabled="newsubcategorycostLoading"
            @click="submit"
          >
            <i v-if="newsubcategorycostLoading" class="el-icon-loading"></i>
            Yuborish</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  data() {
    return {
      newsubcategorycostLoading: false,
      dialog: false,
      nameInput: '',
      codeInput: '',
      categoryInput: ''
    }
  },
  created() {
    this.$store.dispatch('allCostCategories')
  },
  computed: {
    getAllCostCategories() {
      return this.$store.state.expenses.allCostCategories
    }
  },
  methods: {
    submit() {
      const data = {
        name: this.nameInput,
        code: this.codeInput,
        category: this.categoryInput.id
      }

      this.category = this.categoryInput
      this.newsubcategorycostLoading = true
      this.$store
        .dispatch('createCostSubCategories', data)
        .then(() => {
          this.newsubcategorycostLoading = false
          this.dialog = false
          this.nameInput = ''
          this.codeInput = ''
        })
        .catch((err) => {
          this.newsubcategorycostLoading = false
          console.error(err)
        })
    }
  }
}
</script>

<style></style>
